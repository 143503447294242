import React from "react";
import "./styles.scss";
import logo1 from "../static/i/immergruen-logo.png";
// import logo2 from "../static/i/mri-logo.png";
import logo2 from "../static/i/rewe-logo.png";
import logo3 from "../static/i/kaifu-lodge-logo.png";
import logo4 from "../static/i/awo-logo.png";
import logo5 from "../static/i/brk-logo.svg";
import logo6 from "../static/i/tu-dresden-logo.png";

import logo7 from "../static/i/tui-logo.png";

type Props = {
  hideLogos?: boolean;
  hideTexts?: boolean;
  marginTop?: number;
};

const References = (props: Props) => {
  const logos = [
    // {
    //   src: "/i/social-ninja-logo.png",
    //   opacity: 0.4,
    //   marginRight: 40,
    //   width: 120
    // },
    // {
    //   src: "/i/fu-berlin-logo.png",
    //   opacity: 0.5,
    //   marginRight: 40,
    //   width: 180
    // },
    {
      src: logo1,
      opacity: 0.5,
      marginRight: 40,
      width: 120
    },
    {
      src: logo2,
      opacity: 0.6,
      marginRight: 40,
      width: 110,
      maxWidth: 500
    },
    {
      src: logo7,
      opacity: 0.6,
      marginRight: 40,
      width: 110,
      maxWidth: 500
    },
    // { src: "/i/mia-logo.png", opacity: 0.3, marginRight: 40, width: 110 },

    // { src: "/i/sixt-logo.svg", opacity: 0.3, marginRight: 40, width: 120 },
    // { src: "/i/logo-rotorado.png", opacity: 0.7, marginRight: 40, width: 120 },

    {
      src: logo3,
      opacity: 0.6,
      marginRight: 40,
      width: 100
    },
    {
      src: logo4,
      opacity: 0.4,
      marginRight: 40,
      width: 80,
      height: 60
    },
    {
      src: logo5,
      opacity: 0.4,
      marginRight: 40,
      width: 140
    },
    // {
    //   src: "/i/andreas-apo-logo.svg",
    //   opacity: 0.4,
    //   marginRight: 40,
    //   width: 140
    // },
    { src: logo6, opacity: 0.4, marginRight: 40, width: 130 }
    // { src: "/i/bellavista-logo.svg", opacity: 0.4, marginRight: 40, width: 120 }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const firstSecondLabel = (
    <div>
      Schichten<br></br>erstellt
    </div>
  );

  const secondSecondLabel = (
    <div>
      Stunden<br></br>gestempelt
    </div>
  );

  const shuffle = (harray: any) => {
    var currentIndex = harray.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = harray[currentIndex];
      harray[currentIndex] = harray[randomIndex];
      harray[randomIndex] = temporaryValue;
    }

    return harray;
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div
        className="container"
        style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <div className="referencesContainer pt-60">
          <div className="referencesWrapper">
            {!props.hideLogos && (
              <div
                className="referencesBot"
                style={{ marginTop: props.marginTop }}
              >
                {/* {shuffle(allLogoImgs).map(l => { */}

                {logos.map((l, i) => {
                  return (
                    <img
                      key={i}
                      alt={"a"}
                      src={l.src}
                      className="refLogoGatsbyImage"
                      style={{
                        // opacity: 0.3,
                        opacity: l.opacity,
                        // width: 120,
                        maxWidth: l.maxWidth ? l.maxWidth : "130px",
                        marginRight: l.marginRight,
                        width: l.width,
                        height: l.height ? l.height : "auto"
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
