import React, { useState } from "react";
import "./styles.scss";
import { getEncodedRegisterUrl } from "../helpers/routeHelpers";

const GetStarted = () => {
  const [email, setEmail] = useState("");

  return (
    <div>
      <div className="getStarted pt-60 pb-70 justify-content-center">
        <h2 className="small customH2">Jetzt starten</h2>
        <div className="mb-16 color-heading secondaryText">
          Effizienter planen, arbeiten und dabei Geld sparen.
        </div>
        <div className="mt-10">
          <div className="actionWrapper">
            <input
              style={{ marginRight: 10 }}
              type="email"
              name="email"
              placeholder="Ihre Email Adresse"
              className="input radius4 focus-action-1 color-heading placeholder-heading inputCustomized"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <a href={getEncodedRegisterUrl(email)}>
              <button className="btn action-1 radius4 buttonCustomizedGetStarted">
                Jetzt loslegen
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
