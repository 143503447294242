import React, { useState } from "react";

import "./styles.scss";

import { Shiftplan1 } from "./Shiftplan1/Shiftplan1";
import GetStarted from "../GetStarted/GetStarted";
import FunctionPageHeader from "../FunctionPageHeader/FunctionPageHeader";
import References from "../References/References";
import { Navigation } from "../Navigation/Navigation";
import { IndustrySection2 } from "../IndustrySection2/IndustrySection2";
import Footer from "../Footer/Footer";
import { FeatureWrapper } from "../FeatureWrapper/FeatureWrapper";
import GifSection from "../GifSection/GifSection";
import { MobileFunctionsBlock } from "../MobileFunctionsBlock/MobileFunctionsBlock";
import CustomerSuccess from "../CustomerSuccess/CustomerSuccess";
import { AppStoreLinks } from "../AppStoreLinks/AppStoreLinks";
import { CustomHeader } from "../CustomHeader/CustomHeader";
import mainLandingImg from "../images/landing-dienstplaner-app.png";
import mainLandingImgBg from "../images/landing-dienstplaner-app-bg.png";
import { ReferencesLink } from "../ReferencesLink/ReferencesLink";
import { AbsenceFeatures } from "../AbsenceFeatures/AbsenceFeatures";

const ShiftplanPage = () => {
  const textFeatureShiftplan =
    "Erstellen Sie Dienstpläne mit wenigen Klicks und beziehen sie das Team in die Dienstplanung mit ein. Digitalisieren Sie die Personalplanung in Ihrem Betrieb online mit Aplano.";
  const textFeatureClocking =
    "Erfassen Sie Arbeitszeiten direkt über Aplano. Nutzen Sie entweder das Smartphone oder die Stempeluhr Station über das Tablet oder Rechner.";
  const textFeatureAbsence =
    "Ein online Dienstplaner funktioniert besonders gut mit einem integrierten Urlaubsplaner, sodass Ausfälle durch Urlaub und Krankheit direkt im Dienstplan angezeigt werden können. ";

  const [] = useState("");

  const customVideoDescription =
    "Firmen aus unterschiedlichen Branchen verwenden den Aplano Dienstplaner Online. Eine einfache Einrichtung und benutzerfreundliche Bedienung zeichnet den Aplano Dienstplaner aus.";

  return (
    <div className="mainBetaPage">
      <Navigation backgroundCol={"white"} fontColor={"white"} />

      {/* <FunctionPageHeader
        bright={false}
        image={"../images/aplano-beta-landing-img.jpg"}
        imageAlt="Dienstplan erstellen Aplano"
        title="Dienstplaner"
        text="Erstellen Sie Dienstpläne im Handumdrehen und steigern Sie
        die Effizienz in Ihrem Betrieb mit dem digitalen online
        Dienstplan von Aplano."
      /> */}

      <CustomHeader
        title1={"Dienstplaner"}
        // title2="kostenlos"
        subTitle={
          "Erstellen Sie Dienstpläne im Handumdrehen und steigern Sie die Effizienz in Ihrem Betrieb mit dem digitalen online Dienstplan von Aplano."
        }
        landingImg={mainLandingImg}
        minWidth={600}
        showLiveDemoButton
        imgAlt={"Dienstplaner Image"}
        landingImgBackground={mainLandingImgBg}
      />

      <References />

      <MobileFunctionsBlock />
      {/* <GifSection /> */}
      <FeatureWrapper
        titleA={"Dienstplaner"}
        titleB={"Zeiterfassung"}
        titleC={"Abwesenheiten"}
        textA={textFeatureShiftplan}
        textB={textFeatureClocking}
        textC={textFeatureAbsence}
      />
      <ReferencesLink />
      <IndustrySection2
        text={
          "Nachdem der Dienstplan online erstellt wurde, können sich die Mitarbeiter zu ihren zugewiesenen Schichten mit ihrem Handy oder an einer Stempeluhr Station als Tablet Einstempeln. Gestempelte Arbeitszeiten müssen von einem Manager bestätigt werden um vom Programm übernommen zu werden."
        }
      />
      <AppStoreLinks />

      {/* <Shiftplan1 /> */}

      <AbsenceFeatures
        absenceTextA={
          "Mit dem Abwesenheitskalender behalten Sie immer den Überblick, wer in welchen Zeiträumen nicht verfügbar ist, sodass Sie einen möglichen Personalmangel frühzeitig erkennen können. Im Schichtplan werden die Abwesenheiten entsprechend markiert."
        }
        absenceTextB={
          "Bei kurzfristigen Ausfällen können Schichten freigegeben werden, um potenzielle Vertreter über die Lücke im Schichtplaner online über die App zu benachrichtigen."
        }
        absenceTextC={
          "Das Personal kann über die Aplano App Urlaubsanträge einreichen und auf eine Bestätigung durch einen Manager warten. Bei Bestätigung wird der Mitarbeiter automatisch informiert und das Urlaubskonto angepasst. Im Schichtplan werden die abwesenden Tage markiert."
        }
      />

      <CustomerSuccess
        url={"https://www.youtube.com/embed/GmoNaWl7TVo"}
        title={"Unsere Kunden vertrauen auf Aplano"}
        description={customVideoDescription}
        descTextA={
          "Vor einiger Zeit hat auch der Rotorado Bikestore in Hamburg den online Dienstplan von Aplano eingeführt."
        }
        descTextB={
          "Auch die Apotheke im Herzen der Hamburger Sternschanze nutzt Aplano für die Dienstplanung."
        }
      />

      {/* </div> */}

      {/* <Shiftplan3 />
      <Shiftplan4 />

      <Shiftplan5 /> */}
      {/* </div> */}

      <GetStarted />
      <Footer />
    </div>
  );
};

export default ShiftplanPage;
