import React, { useState, useEffect } from "react";
import "./styles.scss";
import VisibilitySensor from "react-visibility-sensor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faQuoteLeft, faStar } from "@fortawesome/free-solid-svg-icons";

type Props = {
  url: string;
  title: string;
  description: string;
  descTextA: string;
  descTextB: string;
};

const CustomerSuccess = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [toggleModal, setModalToggle] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  return (
    <div className="customerSuccessMain">
      <div className="customerSuccessWrapper">
        <VisibilitySensor
          delayedCall
          partialVisibility
          onChange={v => !!v && setIsVisible(v)}
        >
          <div className="mainSuccessAndReviewWrapper">
            <div className="successHeadingTextWrap">
              <div className="customerSuccessHeader">{props.title}</div>
              <div className="customerSuccessDescription">
                {props.description}
              </div>
            </div>
            <div
              className="reviewTextWrap"
              style={{ background: "whitesmoke" }}
            >
              <div className="iconStarWrapperSectionBright">
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
              </div>
              <div className="customerReviewText">
                {
                  '"Was mich unabhängig von den Funktionalitäten absolut begeistert, ist der tolle Kundenservice. Manche Funktionsanfrage von mir wurden so schnell umgesetzt das ich mich manchmal kneifen musste."'
                }
              </div>
              <div className="customerReviewName">{"Stefan Lanz"}</div>
              <div className="platformLogoWrapper">All in Sports GmbH</div>
            </div>
            <div className="reviewTextWrap" style={{ background: "#19a0ff" }}>
              <div className="iconStarWrapperSectionDark">
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
              </div>
              <div className="customerReviewText" style={{ color: "white" }}>
                {
                  '"Wir benutzen den Online Schichtplaner von Aplano in mehreren Standorten und koordinieren unsere Mitarbeiter nun wesentlich einfacher als mit Excel und Whatsapp."'
                }
              </div>
              <div className="customerReviewName" style={{ color: "white" }}>
                {"Tiborg Halveg"}
              </div>
              <div className="platformLogoWrapper" style={{ color: "white" }}>
                Popal
              </div>
            </div>
            <div
              className="reviewTextWrap"
              style={{ background: "whitesmoke" }}
            >
              {/* <div className="quoteWrapper">
                <FontAwesomeIcon icon={faQuoteLeft} size="lg" />
              </div> */}
              <div className="iconStarWrapperSectionBright">
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
              </div>
              <div className="customerReviewText">
                {
                  '"Ich plane Schichten nur noch mit Aplano. Das System funktioniert super und hat mir viel Arbeit abgenommen."'
                }
              </div>
              <div className="customerReviewName">{"Natalie Wendt"}</div>
              <div className="platformLogoWrapper">MIA the Store</div>
            </div>

            <div className="reviewTextWrap" style={{ background: "#19a0ff" }}>
              <div className="iconStarWrapperSectionDark">
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
              </div>
              <div className="customerReviewText" style={{ color: "white" }}>
                {
                  '"Wir managen mit Aplano unsere kleine Seifenmanufaktur und sind mehr als zufrieden. Vor allem der Support ist sensationell. Meist bekommt man innerhalb weniger Minuten die Lösung für sein Problem."'
                }
              </div>
              <div className="customerReviewName" style={{ color: "white" }}>
                {"Daniel Wagner"}
              </div>
              <div className="platformLogoWrapper" style={{ color: "white" }}>
                Münchner Waschkultur
              </div>
            </div>
            <div
              className="reviewTextWrap"
              style={{ background: "whitesmoke" }}
            >
              <div className="iconStarWrapperSectionBright">
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
                <FontAwesomeIcon icon={faStar} className="mr-5" />
              </div>
              <div className="customerReviewText">
                {
                  '"Ich find die App sehr gut, werde über das Wesentliche benachrichtigt und kann mit dem der Schichtplaner Online App von überall den Dienstplan anpassen."'
                }
              </div>
              <div className="customerReviewName">{"Anne Kehler"}</div>
              <div className="platformLogoWrapper">Deificus Art</div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default CustomerSuccess;
