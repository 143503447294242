import React, { useState } from "react";
import { useWindowWidth } from "../helpers/useWindowWidth";
import { MobileNavigation } from "../MobileNavigation/MobileNavigation";
import "./styles.scss";
import { useScroll } from "../helpers/useScroll";
import ShiftPlanIcon from "../static/i/dienstplanung.svg";
import AbsenceIcon from "../static/i/abwesenheit.svg";
import AvailabilityIcon from "../static/i/verfuegbarkeit.svg";
import ClockingIcon from "../static/i/zeiterfassung.svg";
import ReportingIcon from "../static/i/auswertung.svg";
import MobileIcon from "../static/i/mobileapp2.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import logoBlue from "../static/i/aplano-logo-blue.svg";
import logoWhite from "../static/i/aplano-logo-white.svg";

export const Navigation = props => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropDown = show => {
    setShowDropdown(show);
  };

  const width = useWindowWidth();
  const scroll = useScroll();

  const linkColor = props.startWhite ? "white" : "#283845";

  return (
    <div>
      {width < 1200 ? (
        <MobileNavigation displayWhite={props.displayWhite} />
      ) : (
        <nav
          className={
            scroll.y > 1
              ? "header_menu_5 pb-10 headerMainWrapper showHeader"
              : "header_menu_5 pb-10 headerMainWrapper hideHeader"
          }
        >
          <div
            style={{
              borderBottom: "1px solid whitesmoke",
              width: "100%",
              background: "white",
              paddingTop: 10
            }}
          >
            <div
              className="container px-0"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 6,
                margin: "auto"
              }}
            >
              <div className="reviewNavWrap">
                <div className="iconStarNavWrapper">
                  <FontAwesomeIcon icon={faStar} className="mr-5" />
                  <FontAwesomeIcon icon={faStar} className="mr-5" />
                  <FontAwesomeIcon icon={faStar} className="mr-5" />
                  <FontAwesomeIcon icon={faStar} className="mr-5" />
                  <FontAwesomeIcon icon={faStar} className="mr-5" />
                </div>
                <div className="reviewNavQuote">
                  "Aplano hat uns geholfen effektiver Schichtplanänderungen mit
                  den Mitarbeitern zu kommunizieren." - Edvard G., Andreas
                  Apotheke
                </div>
              </div>
              <a href="https://web.aplano.de" className="loginButtonNavTop">
                <div className="">Login</div>
              </a>
            </div>
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              className="container"
              style={{ paddingTop: 12, width: "100%" }}
            >
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo" style={{ display: "flex" }}>
                    <a href="/">
                      {props.startWhite ? (
                        <img
                          width={100}
                          src={scroll.y > 1 ? logoBlue : logoWhite}
                          style={{ paddingTop: "6px" }}
                        />
                      ) : (
                        <img
                          width={100}
                          src={logoBlue}
                          style={{ paddingTop: "6px" }}
                        />
                      )}
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-9 d-flex justify-content-end align-items-center f-18 medium"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <>
                    <div style={{ position: "relative" }}>
                      <a
                        href="https://www.aplano.de/funktionen"
                        className={
                          props.startWhite
                            ? "functionNavLink mx-15 navMainLink whiteLink"
                            : "functionNavLink mx-15 navMainLink"
                        }
                        onMouseOver={toggleDropDown.bind(this, true)}
                        onMouseLeave={toggleDropDown.bind(this, false)}
                        style={{ color: scroll.y > 1 ? "#283845" : linkColor }}
                      >
                        Funktionen
                      </a>
                      <div
                        className="dropdownList"
                        onMouseOver={toggleDropDown.bind(this, true)}
                        onMouseLeave={toggleDropDown.bind(this, false)}
                      >
                        <div
                          className={
                            showDropdown
                              ? "dropdownListHolder showDropDown"
                              : "dropdownListHolder hideDropDown"
                          }
                        >
                          <a href="https://www.aplano.de/schichtplaner">
                            <div className="dropdownItemWrapper">
                              <div className="dropdownImgWrapper">
                                <img
                                  className="dropdownIcon"
                                  src={ShiftPlanIcon}
                                  alt="Dienstplan Icon"
                                />
                              </div>
                              <div className="dropdownItemText">
                                <div className="listItemLink">
                                  Schichtplaner
                                </div>
                                <div className="dropdownIconDescription">
                                  Details zum Dienstplan
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="seperationLine"></div>

                          <a href="https://www.aplano.de/urlaubsplaner">
                            <div className="dropdownItemWrapper">
                              <div className="dropdownImgWrapper">
                                <img
                                  className="dropdownIcon"
                                  src={AbsenceIcon}
                                  alt="Abwesenheiten Dienstplan Icon"
                                />
                              </div>
                              <div className="dropdownItemText">
                                <div className="listItemLink">
                                  Abwesenheiten
                                </div>

                                <div className="dropdownIconDescription">
                                  Urlaubsplaner und mehr
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="seperationLine"></div>

                          <a href="https://www.aplano.de/zeiterfassung">
                            <div className="dropdownItemWrapper">
                              <div className="dropdownImgWrapper">
                                <img
                                  className="dropdownIcon"
                                  src={ClockingIcon}
                                  alt="Zeiterfassung Dienstplan Icon"
                                />
                              </div>
                              <div className="dropdownItemText">
                                <div className="listItemLink">
                                  Zeiterfassung
                                </div>
                                <div className="dropdownIconDescription">
                                  Stempeluhr Funktion
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="seperationLine"></div>

                          <a href="https://www.aplano.de/verfuegbarkeiten">
                            <div className="dropdownItemWrapper">
                              <div className="dropdownImgWrapper">
                                <img
                                  className="dropdownIcon"
                                  src={AvailabilityIcon}
                                  alt="Verfügbarkeiten Dienstplan Icon"
                                />
                              </div>
                              <div className="dropdownItemText">
                                <div className="listItemLink">
                                  Verfügbarkeiten
                                </div>
                                <div className="dropdownIconDescription">
                                  Wer kann wann arbeiten
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="seperationLine"></div>
                          <a href="https://www.aplano.de/auswertung">
                            <div className="dropdownItemWrapper">
                              <div className="dropdownImgWrapper">
                                <img
                                  className="dropdownIcon"
                                  src={ReportingIcon}
                                  alt="Auswertung Dienstplan Icon"
                                />
                              </div>
                              <div className="dropdownItemText">
                                <div className="listItemLink">Auswertung</div>
                                <div className="dropdownIconDescription">
                                  Alle Schichtdaten im Blick
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="seperationLine"></div>
                          <a href="https://www.aplano.de/dienstplan-app">
                            <div className="dropdownItemWrapper">
                              <div className="dropdownImgWrapper">
                                <img
                                  className="dropdownIcon"
                                  src={MobileIcon}
                                  alt="Mobile App Icon"
                                />
                              </div>
                              <div className="dropdownItemText">
                                <div className="listItemLink">Mobile App</div>
                                <div className="dropdownIconDescription">
                                  Unsere Smartphone App
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <a
                      href="https://www.aplano.de/kontakt"
                      className={
                        props.startWhite
                          ? "link mx-15 navMainLink whiteLink"
                          : "link mx-15 navMainLink"
                      }
                      style={{ color: scroll.y > 1 ? "#283845" : linkColor }}
                    >
                      Kontakt
                    </a>
                    <a
                      href="https://www.aplano.de/preise"
                      className={
                        props.startWhite
                          ? "link mx-15 navMainLink whiteLink"
                          : "link mx-15 navMainLink"
                      }
                      style={{ color: scroll.y > 1 ? "#283845" : linkColor }}
                    >
                      Preise
                    </a>
                    <a href="https://www.aplano.de/registrieren">
                      <div className="registerFreeButton mr-0 ml-10">
                        Registrieren
                      </div>
                    </a>
                    {/* <a
                    href="https://web.aplano.de"
                    className="link color-main mx-8 navMainLink"
                  >
                    <div className="navLoginButton">Login</div>
                  </a> */}
                  </>
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};
