import React, { useState, useEffect } from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarTimes,
  faCalendarCheck,
  faHome,
  faPlane,
  faClock,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import mobileAppIcon from "../static/i/mobileapp.svg";
import img1 from "../images/myPlan-mobile-img.png";
import img2 from "../images/teamPlan-mobile-img.png";
import img3 from "../images/absence-mobile-img.png";
import img4 from "../images/availability-mobile-img.png";
import img5 from "../images/dashboard-mobile-img.png";
import img6 from "../images/clocking-mobile-img.png";

let timeoutRef: any;

type Props = {};

export const MobileFunctionsBlock = (props: Props) => {
  const mobileFunctionList = [
    {
      id: "myPlan",
      imgUrl: img1,
      header: "Mein Plan",
      description:
        "Eigenen Dienstplan einsehen und verwalten inkl. Anträge zu Schichttausch, Schichtänderung und Zeiterfassung",
      icon: faCalendarCheck
    },
    {
      id: "teamPlan",
      imgUrl: img2,
      header: "Team Plan",
      description:
        "Dienstplan des gesamten Standortes einsehen und bearbeiten, offene Schichten hinzufügen und Anträge verwalten",
      icon: faCalendarAlt
    },
    {
      id: "absences",
      imgUrl: img3,
      header: "Abwesenheiten",
      description:
        "Abwesenheiten des Monats/Jahres anzeigen und verwalten. Bearbeiten Sie Urlaubsanträge von Mitarbeitern und mehr",
      icon: faPlane
    },
    {
      id: "availability",
      imgUrl: img4,
      header: "Verfügbarkeiten",
      description:
        "Tragen Sie eintägige oder mehrtägige Verfügbarkeiten ein und vermeiden Sie Konflikte bei der Dienstplanung",
      icon: faCalendarTimes
    },
    {
      id: "dashboard",
      imgUrl: img5,
      header: "Dashboard",
      description:
        "Verwalten Sie Anträge von Mitarbeitern und behalten Sie alle Aktivitäten des Teams im Blick auf dem Dashboard",
      icon: faHome
    },
    {
      id: "clocking",
      imgUrl: img6,
      header: "Zeiterfassung",
      description:
        "Lassen Sie Mitarbeiter Arbeitszeiten per Smartphone erfassen. Das System gleicht automatisch die Stundenkonten ab",
      icon: faClock
    }
  ];

  const [imageId, setImageId] = useState("myPlan");
  const [videoCount, setVideoCount] = useState(0);

  const [activeClass, setAnimationClass] = useState("show");

  const handleAnimationChange = () => {
    setAnimationClass("unShow");
    setTimeout(() => setAnimationClass("show"), 100);
  };

  const handleSetImageId = (num: any, id: any) => {
    handleAnimationChange();
    setTimeout(() => setImageId(id), 100);

    setVideoCount(num);
  };

  const handleVideoCount = (num: any) => {
    setVideoCount(num);
  };

  useEffect(() => {
    timeoutRef = setTimeout(() => setVideoIcon(), 8000);
    return () => {
      clearTimeout(timeoutRef);
    };
  }, [videoCount]);

  const setVideoIcon = () => {
    let currentCount = videoCount === 5 ? 0 : videoCount + 1;
    setVideoCount(currentCount);

    const videoIconIds = mobileFunctionList.map(item => item.id);
    handleAnimationChange();
    setTimeout(() => setImageId(videoIconIds[currentCount]), 100);
  };

  const currentImagePath = mobileFunctionList.find(item => item.id === imageId)!
    .imgUrl;

  return (
    <div className="mobileFunctionsBlockMain pt-60 pb-60 px-0 container">
      <div className={"mobileShowcaseWrapper" + " " + activeClass}>
        <img src={currentImagePath} className={"mobileShowcaseImage"} />
      </div>
      <div className="mobileFunctionBlockItems">
        <div className="mobileFunctionsHeader">
          <img
            src={mobileAppIcon}
            alt="random"
            style={{ marginRight: 20, width: 34 }}
          />
          Mobile Funktionen
        </div>
        {mobileFunctionList.map((item, i) => {
          const highlightItem = item.id === imageId ? "activeItem" : "";
          return (
            <div
              className={"mobileFunctionItem" + " " + highlightItem}
              onClick={() => handleSetImageId(i, item.id)}
              key={item.id}
            >
              <div className="mobileFunctionItemHeadWrapper">
                <div className="mobileFunctionsItemHeaderIcon">
                  <FontAwesomeIcon icon={item.icon} />
                </div>
                <div className="mobileFunctionsItemHeader">{item.header}</div>
              </div>
              <div className="mobileFunctionItemDescription">
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
