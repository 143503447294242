import React from "react";
import "./styles.scss";
import capterraLogo from "../images/capter-logo.png";
import trustPilotLogo from "../images/trustp-logo.png";
import provenExpertLogo from "../images/provex-logo.png";
import googlePlayLogo from "../images/googplay-logo.png";
import appleStoreLogo from "../images/applstore-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";

// import trustStar from "../../images/trustStar.svg";

type Props = {};

export const ReferencesLink = (props: Props) => {
  return (
    <div className="referencesLinkMain">
      <div className="container">
        <div className="referencesLinkWrapper">
          <h3 className="referencesLinkTitle">
            {"10.000+ Nutzer vertrauen auf Aplano"}
          </h3>

          <div className="referencesLinkLogos">
            <div className="testimonialLogoWrapper">
              <div className="testimonialReviewStars">
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
              </div>

              <img
                alt="Bewertung Aplano"
                src={capterraLogo}
                className="referencesLinkLogoImg"
              />
            </div>

            <div className="testimonialLogoWrapper">
              <div className="testimonialReviewStars">
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStarHalf} className="mr-5 f-20" />
              </div>

              <img
                alt="Bewertung Aplano"
                src={trustPilotLogo}
                className="referencesLinkLogoImg"
              />
            </div>
            <div className="testimonialLogoWrapper">
              <div className="testimonialReviewStars">
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
              </div>

              <img
                alt="Bewertung Aplano"
                src={provenExpertLogo}
                className="referencesLinkLogoImg"
              />
            </div>
            <div className="testimonialLogoWrapper">
              <div className="testimonialReviewStars">
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStar} className="mr-5 f-20" />
                <FontAwesomeIcon icon={faStarHalf} className="mr-5 f-20" />
              </div>

              <img
                alt="Bewertung Aplano"
                src={appleStoreLogo}
                className="referencesLinkLogoImg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
