import React from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { CustomLinkButton } from "../CustomLinkButton/CustomLinkButton";
import absenceClayMock from "../images/absence-clay-mockup.png";

type Props = {
  absenceTextA: string;
  absenceTextB: string;
  absenceTextC: string;
};

export const AbsenceFeatures = (props: Props) => {
  return (
    <section className="pt-80 pb-40 bg-light absenceFeatureBlock">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6 col-sm-14 firstColAbsenceFeatures">
            <h2 className="small alignLeftText" style={{ fontWeight: 700 }}>
              {"Abwesenheiten im Griff"}
            </h2>
            <p className="mt-30 mb-55 f-16 color-heading text-adaptive description alignLeftText">
              {props.absenceTextA}
            </p>
            <div className="mb-50 block">
              <div className="mb-15 f-16 semibold text-uppercase sp-20 title alignLeftText">
                <FontAwesomeIcon icon={faCheck} className="mr-15" />
                {"Krankheitstage"}
              </div>
              <div className="color-heading op-7 text-adaptive alignLeftText">
                {props.absenceTextB}
              </div>
            </div>
            <div className="mb-50 block">
              <div className="mb-15 f-16 semibold text-uppercase sp-20 title alignLeftText">
                <FontAwesomeIcon icon={faCheck} className="mr-15" />
                {"Urlaubsanfragen"}
              </div>
              <div className="color-heading op-7 text-adaptive mb-20 alignLeftText">
                {props.absenceTextC}
              </div>
              <CustomLinkButton
                link={"https://www.aplano.de/registrieren"}
                linkText={"Kostenlos starten"}
              />
            </div>
          </div>
          <div
            className="col-xl-7 col-lg-6 col-sm-14"
            style={{ position: "relative" }}
          >
            <div style={{ maxWidth: 1200 }} className="absencesBackground">
              <img
                alt="Aplano Dienstplan Abwesenheiten Feature"
                src={absenceClayMock}
                className="bg absenceFeatureImage"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
