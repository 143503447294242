import React, { useState } from "react";
import "./styles.scss";
import { Navigation } from "..//Navigation/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type Props = {
  title1: string;
  title2?: string;
  titleSmall?: boolean;
  titleExtraSmall?: boolean;
  subTitle: string;
  landingImg: string;
  minWidth: number;
  showLiveDemoButton?: boolean;
  smallLandingImg?: string;
  imgAlt?: string;
  marginLeft?: number;
  landingImgBackground: string;
  fontSize?: number;
};

export const CustomHeader = (props: Props) => {
  const [email, setEmail] = useState("");

  const title1Class = props.titleSmall
    ? "customHeaderTitleFontSizeSmall"
    : props.titleExtraSmall
    ? "customHeaderTitleFontSizeExtraSmall"
    : "customHeaderTitleFontSize";

  const title2Class = props.titleSmall
    ? "subHeaderFontSizeSmall"
    : props.titleExtraSmall
    ? "subHeaderFontSizeExtraSmall"
    : "subHeaderFontSize";

  return (
    <header className="customHeaderMain">
      <div>
        <Navigation backgroundCol="#f9f9f9" />
      </div>

      <section className="headerMainContainer container">
        <div className="col-12 col-xl-12 col-lg-12 col-sm-10 customFormAndImgWrapper">
          <div className="headerFormWrapper">
            <div className="titleWrapper">
              <h1 className={title1Class + " customHeaderMainTitle"}>
                {props.title1}
              </h1>
              <div className="freeSubHeader">kostenlos</div>
            </div>
            <div className="smallScreenImageWrapper">
              <img
                src={props.landingImg}
                alt="Aplano Dienstplaner Header"
                className="customHeaderImageSmall onlySmallScreen"
                style={{
                  boxShadow: "0px 5px 40px 0px #00000040",
                  borderRadius: 6
                }}
              />
            </div>

            <p className="desktopSubHeaderText">{props.subTitle}</p>

            {/* {renderInputField()} */}
            <div className="customHeaderButtonWrapper">
              <a
                href={"https://www.aplano.de/registrieren"}
                className="customHeaderButton"
              >
                {"Kostenlos testen"}
              </a>
            </div>
            <p
              style={{ opacity: 0.6, marginTop: 15, fontSize: 14 }}
              className="freeForUpTo"
            >
              {"Kostenlos für bis zu 50 Mitarbeiter"}
            </p>
          </div>
          <div className="customImageWrapper">
            <img
              src={props.landingImg}
              alt="Aplano Dienstplaner Header"
              className="customHeaderImage"
            />

            <img
              src={props.landingImgBackground}
              alt="Aplano Dienstplaner Header"
              className="customHeaderImageBackground"
            />
          </div>
        </div>
      </section>
    </header>
  );
};
