import React from "react";
import "./styles.scss";
import applicationImg from "../images/application_7_img_1@2x.png";
import applicationImg2 from "../images/application_7_img_2@2x.png";

export const AppStoreLinks = () => {
  return (
    <section className="pt-60 pb-40 text-center application_7 appStoreLinksMain">
      <div className="container px-xl-0">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="pb-50 color-white f-22 medium appstoreText">
            Lade Dir jetzt unsere neue Dienstplaner App im App Store für iPhone
            oder im Google Play Store für Android herunter.
          </div>
        </div>
        <div className="row buttonsRow" style={{ justifyContent: "center" }}>
          <div
            className="mb-10 mb-sm-0 mr-sm-2"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-around",
            //   alignItems: "center"
            // }}
          >
            <a
              href="https://apps.apple.com/de/app/aplano/id1497688145"
              className="radius6"
            >
              <img
                alt="Aplano App - App Store Download Link"
                src={applicationImg}
                className="img-fluid buttonLeftLink"
                style={{ width: 160 }}
              />
            </a>
          </div>
          <div className="ml-sm-2 d-flex justify-content-center align-items-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.aplano&hl=de"
              className="radius6"
            >
              <img
                alt="Aplano App - Play Store Download Link"
                src={applicationImg2}
                className="img-fluid"
                style={{ width: 160 }}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
