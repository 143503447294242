import React from "react";
import "./App.scss";
import ShiftplanPage from "./ShiftplanPage/ShiftplanPage";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Dienstplaner</title>
        <meta
          name="description"
          content="Dienstplan online erstellen und kostenlos loslegen. Dienstpläne online anlegen und Mitarbeiter per App einladen."
        />
      </Helmet>
      <ShiftplanPage />
    </div>
  );
}

export default App;
